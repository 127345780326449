import axios from '@/http/axios'
import {createCancelTokenHandler, makeQuery} from "../../axios/utils";

const cancelTokenHandlerObject = {
  getReceives: createCancelTokenHandler('getReceives'),
  getReceiveActivitiesLog: createCancelTokenHandler('getReceiveActivitiesLog')
}

export function getReceives (page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/treasury/receive${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getReceives'].handleRequestCancellation().token
  })
}
export function printReceive ( filters = {}, sorts = []) {
  let queries = makeQuery(0, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/treasury/receive?export=pdf${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getReceive (id) {

  return axios({
    url: `v1/admin/treasury/receive/${id}`,
    method: 'get'
  })
}

export function getReceiveActivitiesLog (id, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`
  return axios({
    url: `v1/admin/treasury/receive/${id}/activity-logs${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getReceiveActivitiesLog'].handleRequestCancellation().token
  })
}

export function confirmReceive (id, payment) {

  return axios({
    url: `v1/admin/treasury/receive/${id}/confirm`,
    data: payment,
    method: 'post'
  })
}

export function insertReceive (payment) {

  return axios({
    url: 'v1/admin/treasury/receive',
    data: payment,
    method: 'post'
  })
}

export function deleteReceive (id) {
  return axios({
    url: `v1/admin/treasury/receive/${id}`,
    method: 'delete'
  })
}
